<template>
  <div id="shipment-list">
    <div v-if="isLoading" class="loading loading-big" style="margin-top: 25%"></div>
    <div v-else class="m-0 p-0">
    <div class="position-relative">
      <div class="d-line-flex dates-wrapper w-100">
          <div class="d-inline-flex p-3 date-box-wrapper justify-content-between">
            <h5 class="">Depart Date: </h5>
            <div class="d-inline-flex">
              <div class="col-6">
                <datepicker 
                  v-model="dateFilterObj.departFrom"
                  format="dd/MM/yyyy"
                  placeholder="DD/MM/YYYY"
                ></datepicker>
              </div>
              <span>To</span>
              <div class="col-6">
                <datepicker 
                  v-model="dateFilterObj.departTo"
                  format="dd/MM/yyyy"
                  placeholder="DD/MM/YYYY"
                ></datepicker>
              </div>
            </div>
          </div>
          <div class="d-inline-flex p-3 ml-5 date-box-wrapper justify-content-between">
            <h5 class="my-auto mr-3">{{ isBatchCancel ? 'Posting Date':'Complete Date' }}: </h5>
            <div class="d-inline-flex">
              <div class="col-6">
                <datepicker 
                  v-model="dateFilterObj.completeFrom"
                  format="dd/MM/yyyy"
                  placeholder="DD/MM/YYYY"
                ></datepicker>
              </div>
              <span>To</span>
              <div class="col-6">
                <datepicker 
                  v-model="dateFilterObj.completeTo"
                  format="dd/MM/yyyy"
                  placeholder="DD/MM/YYYY"
                ></datepicker>
              </div>
            </div>
          </div>
          <!-- Action buttons -->
          <div class="ml-auto">
            <b-button class="ml-2" @click="hide2Months=!hide2Months">
              {{ hide2Months ? 'All Shipment' : 'Last 2 m\'th Shipment' }}
            </b-button>

            <b-button class="ml-2" @click="clearDateObj()">Clear date</b-button>

            <template v-if="isBatchCancel">
              <b-button class="ml-2"
                v-if="listToShow.length > 0"
                @click="doBatchCancel(false)">Confirm</b-button>
              <b-button class="ml-2" @click="cancelBatchCancel()">Cancel</b-button>
            </template>
            <template v-else>
              <b-button class="ml-2" v-if="fullAccess" @click="startBatchCancel()">Batch Cancel</b-button>
              <b-button class="ml-2"
                v-if="!isBatchCancel"
                :disabled="this.listTotal == 0" 
                @click="handleExportExcel()">Export</b-button>
            </template>
          </div>
          <b-form-select 
            v-model="statusFilter" 
            :options="availableShipmentStatus"
            class="status-dropdown"
          />
      </div>
      <b-alert class="d-line-flex banner-batch-cancel w-80" :show="isBatchCancel">
        <h4>Batch Shipment Cancellation</h4>
        <p>{{
          listToShow.length > 0 ? 'Please choose shipment to cancel by clicking the row and click Confirm to proceed'
          : 'There is no shipment available to be cancelled'
        }}</p>
      </b-alert>
    </div>
    <div class="table-wrapper">
      <VueBootstrap4Table 
        :classes="classes"
        :rows="listToShow" 
        :columns="columns" 
        :config="config"
        :total-rows="listTotal"
        :selected_items="selectedItems"
        @on-change-query="onChangeQuery"
        @refresh-data="getList"
        @route-to-detail="handleRouteToDetail"
        @pass-searched-result="handleSearchedResult"
      >
        <template slot="empty-results">
          <div
            v-if="!isLoading"
            class="bold-text"
          >No records found</div>
          <div
            v-if="isLoading"
            class="row justify-content-center mt-3"
          ><div class="loading"></div></div>
        </template>
        <template slot="paginataion" slot-scope="props">
          {{props.currentPageRowsLength}} of {{props.originalRowsLength}}
        </template>
        <template slot="column_custom-ship-to-code-and-ship-to-add-1">
          <div>
            Ship to Code
            <br/>
            Ship to Address
          </div>
        </template>
        <template slot="custom-ship-to-code-and-ship-to-add-1" slot-scope="props">
          <div>
            <b>{{props.row.shipToCode}}</b>
            <br/>
            {{props.row.shipToAddress1}}
          </div>
        </template>
        <template slot="custom-status" slot-scope="props">
          <div :style="{'color': assignColorForStatus(props.row.status)}">
            {{parseShipmentStatus(props.row.status)}}
          </div>
        </template>
        <template slot="column_custom-posting-date">
          <div>Posting<br/>Date</div>
        </template>
        <template slot="custom-posting-date" slot-scope="props">
          <div>{{props.row.postingDate | parseDate}}</div>
        </template>
        <template slot="column_custom-depart-date">
          <div>Depart<br/>Date</div>
        </template>
        <template slot="custom-depart-date" slot-scope="props">
          <div>{{props.row.departTimestamp | parseDate}}</div>
        </template>
        <template slot="column_custom-complete-date">
          <div>Complete<br/>Date</div>
        </template>
        <template slot="custom-complete-date" slot-scope="props">
          <div>{{props.row.completeTimestamp | parseDate}}</div>
        </template>
        <template slot="paginataion-previous-button"><i class="arrow my-auto left blur"></i></template>
        <template slot="paginataion-next-button"><i class="arrow my-auto right blur"></i></template>
      </VueBootstrap4Table>
    </div>
    <div style="padding-bottom: 10rem"></div>
    </div>
    <!-- Confirm modal -->
    <b-modal ref="confirm-modal" hide-footer>
      <div class="mx-4">
        <template v-if="selectedItems.length <= 0">
          <h3>Please choose a shipment first</h3>
        </template>
        <template v-else>
          <h3>Are you sure you want to batch cancel {{selectedItems.length}} shipments?</h3><br/>
          <div class="float-right mb-4 w-25">
            <b-button block @click="doBatchCancel(true)">Confirm</b-button>
          </div>
        </template>
      </div>
    </b-modal>
  </div>
</template>
<script>
import * as shipmentActions from "../../store/modules/shipment/actions"
import * as uiActions from "../../store/modules/_shared/ui/actions";
import { EventBus } from "@/utils/EventBus";
import { convertDateFormat } from "../../utils/helpers"
import { export_json_to_excel } from "../../utils/Export2Excel"
import Datepicker from 'vuejs-datepicker';
import moment from "moment"
import cloneDeep from 'lodash/cloneDeep'

/* NOTE:
  Shipment list's pagination is done by BE
  Batch cancel's pagination is done by FE
*/

const emptyDateFilter = {
  departFrom: null,
  departTo: null,
  completeFrom: null,
  completeTo: null,
}
const emptyQueryParams = {
  sort: [],
  filters: [],
  global_search: "",
  per_page: 10,
  page: 1,
}

export default {
  name: "ShipmentManagement",
  components: {
    VueBootstrap4Table: () => import("@/components/ui/VueBootstrap4Table/VueBootstrap4Table"),
    Datepicker
  },
  filters: {
    parseDate: function(value) {
      if (value) return convertDateFormat(value, 'DD/MM/YYYY')
    }
  },
  watch: {
    dateFilterObj: {
      handler() {
        if (this.isBatchCancel) this.doFilter()
        else this.getList()
      },
      deep: true
    },
    hide2Months() {
      this.clearFilter()
      if (this.isBatchCancel) this.doFilter()
      else this.getList()
    },
    statusFilter() {
      if (this.isBatchCancel) this.doFilter()
      else this.getList()
    }
  },
  computed: { },
  data() {
    return {
      list: [],
      listToShow: [],
      listAfterSearched: [],
      listTotal: 0,
      isLoading: false,
      currentUser: this.$store.getters.getUser,
      fullAccess: false,
      hide2Months: true,
      
      // Batch cancel
      isBatchCancel: false,
      selectedItems: [],

      dateFilterObj: cloneDeep(emptyDateFilter),

      availableShipmentStatus: [],
      columns: [{
        label: "id",
        visibility: false,
        name: "shipmentId",
        sort: true,
      },
      {
        label: "Sales Shipment No",
        name: "salesShipmentNo",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: true,
        row_classes: "clickable",
        width: '180px',
      },
      {
        label: "Ship to Customer",
        name: "shipToCustomerName",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: true,
        row_classes: "clickable",
        width: '250px',
      },
      {
        label: "Order No",
        name: "orderNo",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: true,
        row_classes: "clickable",
        width: '150px',
      },
      {
        // label: "Ship to Code Ship to Address 1",
        name: "shipToCode",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: false,
        slot_name: "custom-ship-to-code-and-ship-to-add-1",
        row_classes: "clickable",
      },
      {
        name: "shipToAddress1",
        visibility: false,
      },
      {
        label: "Posting Date",
        name: "postingDate",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: false,
        slot_name: "custom-posting-date",
        row_classes: "clickable",
        width: '100px',
      },
      {
        label: "Vehicle",
        name: "vehicle",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: false,
        row_classes: "clickable",
        width: '100px',
      },
      {
        label: "Driver",
        name: "driver",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: false,
        row_classes: "clickable",
        width: '150px',
      },
      {
        label: "Depart Date",
        name: "departTimestamp",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: false,
        slot_name: "custom-depart-date",
        row_classes: "clickable",
        width: '80px',
      },
      {
        label: "Complete Date",
        name: "completeTimestamp",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: false,
        slot_name: "custom-complete-date",
        row_classes: "clickable",
        width: '100px',
      },
      {
        label: "Status",
        name: "status",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: false,
        slot_name: "custom-status",
        row_classes: "clickable",
        width: '100px',
      }],
      config: {
        emitQuery: false,
        checkbox_rows: false,
        rows_selectable: false,
        route_to_detail_after_select: true,
        server_mode: true,
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50],
        page: 1,
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: false,
        multi_column_sort: false,
        global_search: {
          visibility: true,
          case_sensitive: false,
          showClearButton: false,
          searchOnPressEnter: true,
          searchDebounceRate: 500,
          placeholder: "Search Shipment",
          init: { value: "" },
          class: "shipment-search"
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: cloneDeep(emptyQueryParams),
      statusFilter: null,
      classes: {
        table: {
          "spci-table": true,
        }
      }
    }
  },
   methods: {
    onChangeQuery(queryParams) {
      this.queryParams = cloneDeep(queryParams)
      this.config.global_search.init.value = this.queryParams.global_search // preserve what user searching
      if (this.isBatchCancel == false) {
        if (this.config.per_page != queryParams.per_page) {
          this.queryParams.page = 1
        }
        this.config.per_page = this.queryParams.per_page // fixing bug
        this.config.page = this.queryParams.page // fixing bug
        // fixing bug ARGGG, pls dont use this table again kthxbye
        // for correct displaying
        const sortObj = queryParams.sort[0]
        if (sortObj != null) {
          this.columns = this.columns.map(el => {
            // reset first
            if (el.initial_sort != null) el.initial_sort = false
            el.initial_sort_order = null
  
            if (sortObj.name == el.name) {
              el.initial_sort = true
              el.initial_sort_order = sortObj.order
            }
  
            return el
          })
        }
  
        this.getList()
      }
    },
    handleSearchedResult(list) {
      this.listAfterSearched = list.event_payload
    },
    doFilter(result) {
      if (this.isBatchCancel) {
        let result = cloneDeep(this.list)
        result = this.filterDate(result)
        if (this.statusFilter != null) {
          result = result.filter(el => el.status == this.statusFilter)
        }
        
        this.listToShow = result
        this.listTotal = result.length
      } else {
        this.listToShow = this.isBatchCancel ? result : result.list
        this.listTotal = result.total
      }
    },
    filterDate(list) { // for batch cancel
      let departFrom = moment(this.dateFilterObj.departFrom)
      let departTo = moment(this.dateFilterObj.departTo)
      let completeFrom = moment(this.dateFilterObj.completeFrom)
      let completeTo = moment(this.dateFilterObj.completeTo)
      let postingFrom = this.hide2Months ?  moment().subtract(2, 'months') : null

      return list.filter(el => {
        let show = true
        let depart = moment(el.departTimestamp)
        let complete = this.isBatchCancel ? moment(el.postingDate) : moment(el.completeTimestamp)
        let posting = moment(el.postingDate)

        // Dont show if any criteria is false
        if (departFrom.isValid()) {
          if (depart.isValid()) {
            departFrom.isSameOrBefore(depart, 'days') ? null : show = false
          } else show = false // depart is empty, false!
        }
        if (departTo.isValid()) { 
          if (depart.isValid()) {
            departTo.isSameOrAfter(depart, 'days') ? null : show = false
          } else show = false // depart is empty, false!
        }
        if (completeFrom.isValid()) {
          if (complete.isValid()) {
            completeFrom.isSameOrBefore(complete, 'days') ? null : show = false
          } else show = false // complete is empty, false!
        }
        if (completeTo.isValid()) {
          if (complete.isValid()) {
            completeTo.isSameOrAfter(complete, 'days') ? null : show = false
          } else show = false // completeTo is empty, false!
        }
        if (postingFrom?.isValid()) {
          postingFrom.isSameOrBefore(posting, 'days') ? null : show = false
        }
        return show
      })
    },
    clearDateObj() {
      this.dateFilterObj = cloneDeep(emptyDateFilter)
    },
    clearFilter() {
      this.queryParams = cloneDeep(emptyQueryParams)
      this.clearDateObj()
      this.selectedItems = []
      this.config.per_page = 10
      this.config.page = 1
      this.statusFilter = null
    },

    // Batch cancel
    startBatchCancel() {
      this.config.emitQuery = true
      this.config.server_mode = false
      this.config.preservePageOnDataChange = false
      this.config.checkbox_rows = true
      this.config.rows_selectable = true
      this.config.route_to_detail_after_select = false
      this.isBatchCancel = true
      this.columns = this.columns.map(el => {
        el.initial_sort = false
        el.initial_sort_order = null
        return el
      })
      this.clearFilter()
      this.getCancellableList()
    },
    cancelBatchCancel() {
      this.config.emitQuery = false
      this.config.server_mode = true
      this.config.preservePageOnDataChange = true
      this.config.checkbox_rows = false
      this.config.rows_selectable = false
      this.config.route_to_detail_after_select = true
      this.isBatchCancel = false
      // remove posting date filter when quit
      this.dateFilterObj.completeFrom = null
      this.dateFilterObj.completeTo = null
      this.clearFilter()
      this.getList()
    },
    getCancellableList() {
      this.isLoading = true;
      this.$store.dispatch(shipmentActions.CANCELLABLE_SHIPMENT_LIST_REQUEST, {}).then(res => {
        if (res.status === "ok" && res.response) {
          this.list = res.response;
          this.doFilter()
        }
      })
      .finally(() => {this.isLoading = false})
    },
    async doBatchCancel(confirm) {
      this.$refs["confirm-modal"].toggle("#toggle-btn");
      if (!confirm) return
      let postData = {
        shipmentList: this.selectedItems.map(el => {
          return { id: el.shipmentId }
        }),
        userId: this.currentUser.userId,
      }
      this.$store.dispatch(uiActions.NOTIFICATION_SUCCESS, { message: "Batch cancelling... Please wait" })
      await this.$store.dispatch(shipmentActions.SHIPMENT_BULK_CANCEL_REQUEST, postData).then(res => {
        if (res.status != "ok") throw(res)
        this.$store.dispatch(uiActions.NOTIFICATION_SUCCESS, {
          message: res.response
        })
      }).catch(err => {console.log(err)})
      
      this.cancelBatchCancel()
    },
    filterForCancel(list) {
      return list.filter(shipment => {
        const isCancelledOrCompleted = shipment.status == "SHIPMENT_CANCEL" || shipment.status == "SHIPMENT_COMPLETE"
        const isPostingDateBefore = !moment(shipment.postingDate, "YYYY-MM-DD").isAfter(moment(), 'days')

        if (isCancelledOrCompleted || isPostingDateBefore) return false
        else return true
      })
    },

    async handleExportExcel() {
      const tHeader = [
        "Sales Shipment No",
        "Ship To Customer",
        "Order No",
        "Ship To Code",
        "Ship To Address1",
        "Ship To Address2",
        "Posting Date",
        "Vehicle",
        "Driver",
        "Depart Date",
        "Complete Date",
        "Status",
      ];
      const filterVal = [
        "salesShipmentNo",
        "shipToCustomerName",
        "orderNo",
        "shipToCode",
        "shipToAddress1",
        "shipToAddress2",
        "postingDate",
        "vehicle",
        "driver",
        "departDate",
        "completeDate",
        "status",
      ];

      const list = await this.getList(true)
      const data = this.formatJSONArray(filterVal, list);
      const fileName = this.getFileName()
      
      export_json_to_excel({
        header: tHeader,
        data,
        filename: fileName,
        sheetName: "details",
        autoWidth: true,
        bookType: "xlsx"
      });
    },
    getFileName() {
      let result = "shipment-details_" + moment().format("DD_MM_YYYY_HH_mm_ss").toString();
      let isDateFiltered = this.dateFilterObj.departFrom 
                          || this.dateFilterObj.departTo 
                            || this.dateFilterObj.completeFrom 
                              || this.dateFilterObj.completeTo
      if (isDateFiltered) {
        result += `_date_filtered`
      }
      if (this.queryParams.global_search && this.queryParams.global_search != '') {
        result += `_search_filtered`
      }
      return result
    },
    formatJSONArray(filterVal, jsonArray) {
      return jsonArray.map(eachElement =>
        filterVal.map(val => {
          if (val === "postingDate") {
            return eachElement.postingDate ? 
              convertDateFormat(eachElement.postingDate, 'DD/MM/YYYY') : ""
          } else if (val === "completeDate") {
            return eachElement.completeTimestamp ?
              convertDateFormat(eachElement.completeTimestamp, 'DD/MM/YYYY') : ""
          } else if (val === "departDate") {
            return eachElement.departTimestamp ?
              convertDateFormat(eachElement.departTimestamp, 'DD/MM/YYYY') : ""
          } else if (val === "status") {
            return this.parseShipmentStatus(eachElement.status)
          } else {
            return eachElement[val];
          }
        })
      )
    },
    async getList(forExport = false) {
      this.isLoading = true;
      let orderCol, desc = null
      if (this.queryParams.sort[0] != null) {
        switch(this.queryParams.sort[0].name) {
          case "salesShipmentNo":
            orderCol = "shi.sales_shipment_no"
            break
          case "shipToCustomerName":
            orderCol = "shi.ship_to_customer_name"
            break
          case "orderNo":
            orderCol = "shi.order_no"
            break
        }

        desc = this.queryParams.sort[0].order == "desc"
      }
      const postData = {
        page: forExport ? null : this.queryParams.page,
        limit: forExport ? null : this.queryParams.per_page,
        orderCol, desc,
        search: this.queryParams.global_search,
        shipmentStatus: this.statusFilter,
        fromDepartDate: this.dateFilterObj.departFrom ? moment(this.dateFilterObj.departFrom).format("YYYY-MM-DD").toString() : null,
        toDepartDate: this.dateFilterObj.departTo ? moment(this.dateFilterObj.departTo).format("YYYY-MM-DD").toString() : null,
        fromCompleteDate: this.dateFilterObj.completeFrom ? moment(this.dateFilterObj.completeFrom).format("YYYY-MM-DD").toString() : null,
        toCompleteDate: this.dateFilterObj.completeTo ? moment(this.dateFilterObj.completeTo).format("YYYY-MM-DD").toString() : null,
        fromPostingDate: this.hide2Months ? moment().subtract(2, 'months').format("YYYY-MM-DD").toString() : null
      }

      let result = []
      await this.$store.dispatch(shipmentActions.SHIPMENT_LIST_REQUEST, postData).then(res => {
        if (res.status === "ok" && res.response) {
          if (forExport) {
            result = cloneDeep(res.response.list)
          } else {
            this.doFilter(cloneDeep(res.response))
          }
        }
      })
      .finally(() => {this.isLoading = false;})

      return result
    },
    parseShipmentStatus(status) {
      return this.availableShipmentStatus.find(el => el.value === status).text
    },
    assignColorForStatus(status) {
      return this.availableShipmentStatus.find(el => el.value === status)?.color
    },
    handleRouteToDetail(payload) {
      let url = 'shipment-management/detail/' + payload.event_payload.shipmentId
      this.$router.push(url)
    }
  },
  mounted(){
    EventBus.$emit('toggleSideMenu', 'hide')
    this.getList();
    this.availableShipmentStatus = cloneDeep(this.$store.getters.getAvailableShipmentStatus)
    this.availableShipmentStatus.unshift({ text: 'All', value: null, color: null })

    if (this.currentUser.isAdmin || this.currentUser.isManager) this.fullAccess = true
  }
}
</script>
<style lang='scss'>
#shipment-list {
  .dates-wrapper{
    padding: 0px;
    line-height: 10px;
    height: 40px; 
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    h5 {
      font-size: 1.4rem;
    }
    span {
      margin: auto;
    }
  }
  .date-box-wrapper{
    background: #fff;
    border-radius: 12px;
  }
  .vdp-datepicker{
    border-bottom: 1px solid lightgray;
    &:after {
      padding-bottom: 5px;
    }
  }
  .banner-batch-cancel {
    position: absolute;
    top: 55px;
    left: 0;
    >p {
      margin: 0;
    }
  }
  .table-wrapper{
    padding-top: 7rem;
  }
  .status-dropdown {
    z-index: 2;
    position: absolute;
    top: 84px;
    right: 0;
    width: 9%;
    height: 42px;
    border-radius: 13px;
  }
}


.shipment-search {
  #search-group {
    width: 75%;
    input {
      width: 75%;
      flex: none;
    }
  }
}
</style>